.ImagePreviewContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ImagePreview {
    width: 90%;
    height: 90%;
    object-fit: contain;
}