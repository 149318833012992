.PreviewWrapper {
    width: 10vw;
    height: 15vw;
    min-width: 10em;
    min-height: 15em;
    transition: all 0.25s;
    user-select: none;
    cursor: pointer;
    border-radius: 1em;
    background-color: #181818;
    text-align: center;
    flex-basis: 17.5%;
    font-size: 0.6vw;
    animation: 1s both fadein;
}

.PreviewWrapper:hover {
    transform: scale(1.025);
    color: black;
    background-color: white;
}

.PreviewDisplay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(15vw - 5em);
}

.NameDisplay {
    cursor: pointer;
    user-select: none;
}

.NameDisplay:hover {
    transform: scale(1.05);
    text-decoration: underline;
}

@keyframes fadein {
    0% {
        opacity: 0;
        transform: translateY(10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}