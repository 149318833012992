.Login-Container {
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    animation: Login-Smallzoom 16s alternate infinite;
    flex-direction: column;
}

.Login-Field-Container {
    border: 1px solid rgba(255, 255, 255, 0.75);
    box-shadow: 0 0 3em 0.1em rgba(200, 200, 255, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: max(2em, 2vw);
    margin: max(5em, 5vw);
    min-width: 35vw;
}

.Login-Field {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    padding: max(0.5em, 0.5vw);
    margin: 0.5em;
    font-size: larger;
    outline: none;
    font-family: monospace;
}

.Login-Field-Label {
    margin-left: 0.75em;
    margin-top: 1em;
    font-family: monospace;
    font-size: large;
    user-select: none;
}

.Login-Field-Text {
    color: white;
    align-self: center;
    font-family: monospace;
    font-size: x-large;
    user-select: none;
}

.Login-Button {
    cursor: pointer;
    margin: 0.5em;
    margin-top: 1.5em;
    font-family: monospace;
    padding: max(0.5em, 0.5vw);
    font-size: x-large;
    background-color: white;
    border: 1px solid white;
    box-shadow: 0 0 2em 0.5em rgba(200, 200, 255, 0.35);
    transition: 0.5s all;
    outline: none;
}

.Login-Button:hover {
    transform: scale(1.015);
    transition: 0.5s all;
}

@keyframes Login-Smallzoom {
    0% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}