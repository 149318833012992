.Typewriter h1 {
    color: #fff;
    font-family: monospace;
    overflow: hidden;
    border-right: .125em solid #AAA;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    font-size: 3vw;
    animation:
        Typewriter-Typing 3.5s steps(30, end),
        Typewriter-Blink-Caret .5s step-end infinite;
}

.Typewriter p {
    color: #fff;
    font-family: monospace;
    overflow: hidden;
    border-right: .125em solid #AAA;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    font-size: 1.5vw;
    animation:
        Typewriter-Typing 3.5s steps(30, end),
        Typewriter-Blink-Caret .5s step-end infinite;
}

/* The typing effect */
@keyframes Typewriter-Typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

/* The typewriter cursor effect */
@keyframes Typewriter-Blink-Caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: #AAA
    }
}