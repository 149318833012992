._3dmPreviewContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.matSelectContainer {
    padding-top: 1em;
}

.matSelectRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    margin-left: 30%;
    margin-right: 30%;
}

.matSelectTitle {
    margin: 0;
}

.matSelectDropdown {
    padding: 0.25em;
    background-color: transparent;
    color: white;
    border-radius: 0.5em;
}

.PreviewWrapper:hover * .matSelectDropdown {
    color: black;
}



.modelSelectorContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 2em;
}

.ModelSelectorButton {
    padding: 0.6em;
    margin: 0;
    border: 1px solid white;
    border-radius: 0.5em;
    transition: all 0.25s;
    user-select: none;
    cursor: pointer;
}

.PreviewWrapper:hover * .ModelSelectorButton {
    border: 1px solid black;
}

.ModelSelectorButton:hover {
    transform: scale(1.05);
    background-color: white;
    color: black;
}



.ToggleableSelectorContainer {
    height: 2em;
    gap: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ToggleableSelection {
    height: 1.5em;
    border: 1px solid white;
    padding-left: 0.25em;
    padding-right: 0.25em;
}

.ToggleableSelection:hover {
    text-decoration: underline;
    transform: scale(1.05);
}

.PreviewWrapper:hover * .ToggleableSelection {
    border: 1px solid black;
}