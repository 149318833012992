body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Button {
    padding: 1em;
    border: 1px solid white;
    border-radius: 0.5em;
    transition: all 0.25s;
    user-select: none;
    cursor: pointer;
}

.Button:hover {
    transform: scale(1.05);
    background-color: white;
    color: black;
}

.NavContainer {
    display: flex;
    flex-direction: row;
    gap: 1em;
    justify-content: center;
    align-items: center;
}

.AssetSearch {
    background-color: #181818;
    color: white;
    padding: 1em;
    border: none;
    width: 50%;
    margin-bottom: 2em;
}